<script setup>
</script>

<template>
  <div>
    <div class="wrapper">
      <h1 class="mb-8">
        Hulvati.sk
      </h1>
      <p class="text-lg md:text-2xl leading-relaxed max-w-2xl">
        Poslanci a verejní činitelia, ktorí svojim správaním preukázateľne prispievajú k vulgarizácii verejného diskurzu a k zhoršeniu politickej kultúry na Slovensku.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-3 mt-16 gap-8">
        <router-link :to="{name: 'person', params: { name: 'rudolf-huliak' }}" class="group">
          <div class="aspect-square rounded overflow-hidden">
            <img src="people/rudolf-huliak.jpg" alt="Rudolf Huliak" class="w-full h-full object-cover" />
          </div>
          <div class="flex items-center gap-4">
            <div>
              <h2 class="mt-4 text-xl font-bold mb-0">
                Rudolf Huliak
              </h2>
              <p class="text-sm text-gray-500">
                Poslanec NR SR
              </p>
            </div>
          </div>
        </router-link>

        <router-link :to="{name: 'person', params: { name: 'lubos-blaha' }}" class="group">
          <div class="aspect-square rounded overflow-hidden">
            <img src="people/lubos-blaha.jpg" alt="Ľuboš Blaha" class="w-full h-full object-cover" />
          </div>
          <div class="flex items-center gap-4">
            <div>
              <h2 class="mt-4 text-xl font-bold mb-0">
                Ľuboš Blaha
              </h2>
              <p class="text-sm text-gray-500">
                (Coming soon) Poslanec NR SR
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
