export const people = [{
  "name": "Rudolf Huliak",
  "slug": "rudolf-huliak",
  "image": {
    "src": "people/rudolf-huliak.jpg",
    "alt": "Rudolf Huliak - Poslanec NR SR za SNS - Hulvati"
  },
  "articles": [
    {
      "title": "Urážky a sexistické poznámky",
      "date": "September 25, 2024",
      "content": "Na rokovaní Národnej rady Huliak urážlivo reagoval na vyjadrenie poslankyne Progresívneho Slovenska Lucie Plavákovej o právach plodu. Huliak povedal: „Keď poslankyňa Národnej rady prečíta vo svojom príspevku to, že plod si nezaslúži žiť, tak táto žena si nezaslúži nič iné, len pomenovanie suka.“ Ďalej dodal, že Plavákovú „nepovažuje za ženu“. Jeho výroky vyvolali pobúrenie medzi opozíciou, ktorá ho vyzvala na ospravedlnenie. Huliak odmietol ospravedlniť sa s tým, že sa „ospravedlní svojej suke na dvore“.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Vytrhnutie vyjadrenia z kontextu",
      "date": "September 25, 2024",
      "content": "Huliak obhajoval svoje urážlivé vyjadrenia tým, že reagoval na Plavákovú, ktorá údajne povedala, že plod „nemá právo na život“. V skutočnosti Plaváková toto tvrdenie uviedla v kontexte legislatívy a rozhodnutia Ústavného súdu, ktorý hovorí, že plod nie je právnym subjektom pred narodením. Huliak sa však snažil prekrútiť jej slová a vyvolať dojem, že ide o jej osobný názor.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Zneuctenie žien",
      "date": "September 25, 2024",
      "content": "V rozhovore pre Ereport Huliak tvrdil, že má kladný vzťah k ženám a pravidelne ich odmeňuje čokoládkami a inými pozornosťami. Povedal: „Žena je u mňa žena s veľkým žet. Všetky ženy z môjho okolia, či už je to moja partnerka, alebo moja mama, aj všetky ostatné vedia, čo k ženám cítim.“ Tento postoj bol spochybnený jeho kolegyňami, ktoré poukázali na jeho sexistické správanie.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Sexistické poznámky",
      "date": "September 2024",
      "content": "Poslankyňa PS Tamara Stohlová zdieľala svoju osobnú skúsenosť s Huliakom na sociálnych sieťach. Opísala situáciu, keď ju nazval „násťročným dievčatkom“ a komentoval jej vzhľad pred živým vysielaním. Jej slová: „Huliak mi hovorí, aká som v tom videu bola sexi.“ Stohlová dodala, že Huliak pravidelne komentuje jej vzhľad a robí jej sexistické návrhy na kávu a raňajky.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Agresívne vyjadrenia o zastrelení",
      "date": "November 2023",
      "content": "Po incidente s hovorcom hnutia Slovensko Matúšom Bystrianskym v Abú Zabí Huliak povedal: „Aby niekto išiel za ministerkou na zahraničnú cestu, doslova a dopísmena sa jej dokázal votrieť do limuzíny, takýto človek by mal byť bez mihnutia oka zastrelený.“ Tento výrok vyvolal pobúrenie a vyzvanie na to, aby sa vzdal poslaneckého mandátu.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Urážlivé vyjadrenie o voľbách",
      "date": "November 2022",
      "content": "V súvislosti s komunálnymi voľbami v Očovej Huliak uviedol: „Očová vo svojej histórii nikdy tam ženu nemala a pevne verím, že ani nebude.“ Týmto komentoval svoju hlavnú súperku Miroslavu Rajčokovú, pričom podkopal rodovú rovnosť v voľbách.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Očividné sexistické správanie",
      "date": "Celý rok 2024",
      "content": "Huliak vo verejných diskusiách a televíznych debatách opakovane spochybňoval schopnosti ženských poslankýň na základe ich veku a vzhľadu. Napríklad sa posmieval Stohlovej o tom, že on mal viac skúseností s poľovaním ako ona rokov.",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    },
    {
      "title": "Vyhrážky ochranárom",
      "date": "Roky 2023-2024",
      "content": "Huliak opakovane útočil na ochranára Mariána Hletka. Vo svojich videách hovoril, že by mali „obesiť“ ľudí, ktorí parazitujú na spoločnosti a robia proti Slovensku. Huliak uviedol: „Bohdaj by tvoje deti na teba pľuli, keď ťa zahrabú na cintoríne.“",
      "source": "https://dennikn.sk/4216155/hovorcu-olano-mali-zastrelit-ochranarovi-deti-plut-na-hrob-poslankyni-chvalil-zadok-ako-sa-sprava-rudolf-huliak/"
    }
  ]
}, {
  "name": "Ľuboš Blaha",
  "slug": "lubos-blaha",
  "image": {
    "src": "people/lubos-blaha.jpg",
    "alt": "Ľuboš Blaha - Poslanec NR SR za SMER-SD - Hulvati"
  },
  "articles": [
    {
      "title": "Vulgárne útoky na prezidentku Z. Čaputovú",
      "date": "3. 5. 2022 ",
      "content": "Na stretnutí predstaviteľov strany SMER-SD s občnami v Nitre Ľuboš Blaha hecoval dav k pokriku \"americká\" a z davu sa opakovane ozývalo \"ku*va\". Na stretnutí sa k vulgárnemu vyjadrovaniu pridali aj ďalší politici, vrátane Roberta Fica. Premiér Eduard Heger a minister zahraničných vecí Ivan Korčok ostro kritizovali takéto správanie, pričom Heger zdôraznil, že ide o útok na najvyšších ústavných činiteľov. Prezidentka Čaputová vyjadrila vďaku za podporu a poukázala na dôležitosť slušnosti v spoločnosti.",
      "source": "https://video.aktualne.cz/slovensti-politici-robert-fico-a-ubos-blaha-vulgarne-hovoril/r~413cc526cadd11ec8b18ac1f6b220ee8/r~ba95f464101511ee8c6f0cc47ab5f122/"
    }
  ]
}]