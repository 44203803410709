<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { people } from '@/constants/people'

const route = useRoute()
const router = useRouter()
const person = ref(people.find(person => person.slug === route.params.name))

onMounted(() => {
  if (!person.value) {
    router.push('/')
  } else {
    document.title = `${person.value.name} | Hulvati.sk`
  }
})
</script>

<template>
  <div>
    <div class="wrapper">
      <router-link to="/" class="text-xl">
        &laquo; Všetci ľudia
      </router-link>

      <h1 class="mb-8 mt-4">
        {{ person.name }}
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-16 gap-8">
        <div>
          <img :src="person.image.src" :alt="person.image.alt" class="w-full h-full object-cover rounded max-h-[36rem]" />
        </div>

        <div v-for="(article, index) in person.articles" :key="index" class="p-8 bg-neutral-100 rounded">
          <h2 class="text-2xl mb-0">{{  article.title }}</h2>
          <div class="opacity-50 mb-2">{{  article.date }}</div>
          <p>{{ article.content }}</p>
          <p v-if="article.source"><a rel="nofollow" class="opacity-75 inline-block mt-2" :href="article.source">(zdroj)</a></p>
        </div>
      </div>
    </div>
  </div>
</template>
